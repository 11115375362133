.about-section {
  display: flex;
  width: 100%;
  /* min-height: 40vh; */
  background: #3853a4;
  padding: 8rem;
  position: relative;
  flex-direction: column;
  /* margin-top:100vh ; */
  padding-bottom: 2rem;
  overflow: hidden;
}
.about-container{
  display: flex;
  gap: 5rem;
}
.about-l {
  flex: 1;
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  flex-direction: column;
}
.about-title {
  position: relative;
  color: #ffffff;
  font-family: "summer-ladies";
  font-weight: 400;
  font-size: 36px;
  line-height: 38px;
  height: 4rem;
}
.about-line {
  background: #ef3a37;
  height: 3px;
  border: none;
  outline: none;
  border-radius: 2px 0 0 2px;
  width: 177px;
  position: absolute;
  left: -56px;
  margin-top: 12px;
}
.about-circle {
  height: 12px;
  width: 12px;
  margin-top: 8px;
  position: absolute;
  left: 116px;
  background: #ef3a37;
  border-radius: 50%;
}
.about-exp{
    font-family: 'Poppins', sans-serif;
    color: #FCBA17;
    font-size: 24px;
    font-weight: 800;
    line-height: 31px;
    text-align: right;
    margin-top: -4px;
    text-transform: uppercase;
}
.about-pride{
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: #ffffff;
    font-style: italic;
    font-weight: 300;
    line-height: 24px;
    text-align: right;
}
.about-r {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.story-container{
    font-size: 16px;
    font-weight: 300;
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
    line-height: 24px;
}
.story-container>span{
    color: #FCBA17;
    font-size: 16px;
    text-transform: uppercase;
    margin-right: 5px;
    font-weight: 600;
}
.story-title{
position: relative;
text-transform: uppercase;
font-family: 'Poppins', sans-serif;
font-size: 20px;
line-height: 30px;
height: 4rem;
align-items: flex-start;
color: #ffffff;
font-weight: 700;
}
.story-circle{
height: 12px;
width: 12px;
background: #ef3a37;
border-radius: 50%;
position: absolute;
margin-top: 8px;
}
.story-circle2{
  display: none;
}
.story-line{
    background: #ef3a37;
    height: 3px;
    border: none;
    outline: none;
    border-radius: 2px 0 0 2px;
    width: 160px;
    position: absolute;
    left: 6px;
    margin-top: 12px;
}
.about-background{
    font-size: 200px;
    font-weight: 800;
    font-family: 'Poppins', sans-serif;
    color: rgba(255, 255, 255, 0.7);
    line-height: 300px;
    font-style: normal ;
    mix-blend-mode: normal;
text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
@media screen and (max-width:1025px){
.about-section{
  padding: 3rem;
  padding-top: 7rem;
}
.about-title {
  font-size: 32px;
}
.about-exp{
  font-size: 20px;
  line-height: 28px;
}
.about-pride{
  line-height: 18px;
  font-size: 12px;
}
.story-container{
  font-size: 12px;
  line-height: 20px;
}
.story-container>span{
  font-size: 12px;
}
.story-title{
  font-size: 16px;
}
.about-background{
  font-size: 150px;
}
}
@media screen and (max-width:768px){
.about-section{
  padding: 20px;
  padding-top: 5rem;
}
.about-container{
  flex-direction: column;
    gap: 2rem;
    padding: 20px;
}
.about-l{
  align-items: flex-start;
}
.about-r{
  flex-direction: column-reverse;
  gap: 5px;
}

.about-title{
  font-size: 29px;
}
.about-exp{
  text-align: left;
    font-size: 18px;
    line-height: 22px;
}
.about-pride{
  text-align: left;
  font-size: 11px;
  line-height: 15px;
}
.story-container{
  font-size: 11px;
    line-height: 16px;
    text-align: center;
}
.story-container>span{
  font-size: 11px;
}
.story-title{
  font-size: 15px;
  text-align: center;
  width: 10rem;
  place-self: center;
}
.story-line{
  right: 0px !important;
  left: unset;
}
.story-circle{
  right: 0;
}
.story-circle2{
  display: block;
  left: 0;
}
.about-background {
  font-size: 82px;
  line-height: unset;
}
}