.nav-section {
  height: 7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #3853a4;
  /* position: fixed; */
  width: 100%;
  padding-left: 6rem;
  /* top: -7rem;
      left: 0; */
  z-index: 99999;
  transition: 0.3s linear;
}
.nav-wrapper {
  top: -7rem;
}
.single-nav-wrapper {
  top: -7rem;
}
.single-nav-section {
  height: 7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* position: fixed; */
  width: 100%;
  padding-left: 6rem;
  /* top: -7rem;
      left: 0; */
  z-index: 99999;
  background: #455a64;
  transition: 0.3s linear;
}
.active-nav {
  top: 0;
}
.n-left {
  height: 3rem;
}
.nav-logo {
  height: 3rem;
}
.mob-menu {
  display: none;
}
.n-right {
  background: rgba(30, 30, 30, 0.6);
  padding: 10px 6rem;
  display: flex;
  border-radius: 1rem 0 0 1rem;
  align-items: center;
}
.menu-logo {
  display: none;
}
.menu-links {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
}
.menu-links li {
  list-style: none;
}
.menu-link {
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
  background: transparent;
  padding: 10px;
  border-radius: 15px;
  font-size: 12px;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in-out linear;
  font-family: "Poppins", sans-serif;
}
.menu-links > li:hover > .menu-link {
  background: #ef3a37 !important;
}
.menu-link.active {
  background: #ef3a37;
}

@media screen and (max-width: 1025px) {
  .nav-section {
    padding-left: 3rem;
  }
  .single-nav-section{
    padding-left: 3rem;
  }
  .n-right {
    padding: 10px 1rem;
    padding-left: 55px;
  }
  .menu-links {
    gap: 5px;
  }
  .menu-link {
    font-size: 10px;
  }
}
@media screen and (max-width: 768px) {
  .nav-wrapper {
    top: 0;
  }

  .single-nav-wrapper {
    top: 0;
  }
  .nav-section {
    padding-left: 1rem;
    height: 5rem;
  }
  .single-nav-section{
    padding-left: 1rem;
    height: 5rem;
  }
  .nav-logo {
    height: 2.5rem;
  }
  .n-right {
    flex-direction: column;
    position: absolute;
    width: 100vw;
    top: 0;
    z-index: 999;
    background: #ef3a37;
    top: 0;
    left: 0;
    border-radius: 0;
    padding: 0;
    padding-left: 0;
    transform: translateX(-100vw);
    transition: 0.5s linear;
  }
  .n-right.menu-open {
    transform: translateX(0);
  }
  .menu-links {
    flex-direction: column;
    width: 76vw;
    height: 100vh;
    align-items: unset;
    gap: 15px;
    justify-content: center;
  }
  .menu-link {
    font-size: 20px;
    gap: 21px;
    border-radius: 0;
  }
  .menu-link.active {
    background: none !important;
  }
  .menu-links > li:hover > .menu-link {
    background: #3853a4 !important;
  }
  .menu-logo {
    display: block;
  }
  .menu-logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .menu-logo > img {
    height: 55px;
    width: 100px;
  }
  .mob-menu {
    display: block;
    position: fixed;
    top: 17px;
    right: 17px;
    height: 48px;
    /* background: #ef3a37; */
    width: 48px;
    border-radius: 12px;
    border: none;
    outline: none;
    z-index: 99999;
  }
  .hamburg-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 8px;
    flex-direction: column;
    cursor: pointer;
  }

  .hamberg-menuu {
    height: 5px;
    width: 75%;
    background-color: #fff;
    color: #fff;
    border-radius: 5px;
  }
  .hidden {
    display: none;
  }
  .visible {
    display: inherit;
  }
  .hamburg-menu.clicked :nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px) scale(1.3);
    transition: ease-out 0.5s;
  }
  .hamburg-menu.clicked :nth-child(2) {
    transition: ease-out 0.5s;
    display: none;
  }
  .hamburg-menu.clicked :nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px) scale(1.3);
    transition: ease-out 0.5s;
  }
  .hamburg-menu.unclicked .hamberg-menuu {
    transform: rotate(0) translate(0);
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
  }
}
