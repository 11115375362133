/* .footer-section {
  background: #1c3a2d;
  min-height: 40rem;
  padding: 5rem 7rem;
  width: 100%;
  display: flex;
  gap: 5rem;
  position: relative;
  padding-right: 0;
  overflow: hidden;
  align-items: center;
  margin-top: auto;
} */
.footer-section {
  background: #1c3a2d;
  min-height: 40rem;
  padding: 5rem 5rem;
  width: 100%;
  display: flex;
  gap: 5rem;
  position: relative;
  padding-right: 0;
  overflow: hidden;
  align-items: center;
  margin-top: auto;
  padding-left: 8vw;
}
.footer-l {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex: 1;
}
.footer-title {
  position: relative;
  height: 4rem;
  font-size: 48px;
  font-weight: 400;
  font-family: "summer-ladies";
  color: #fff;
}
.footer-line {
  position: absolute;
  background: #ef3a37;
  height: 3px;
  width: 20rem;
  border: none;
  outline: none;
  margin-top: 12px;
  left: -7rem;
}
.footer-circle {
  position: absolute;
  background: #ef3a37;
  height: 12px;
  width: 12px;
  margin-top: 8px;
  border-radius: 50%;
  left: 13rem;
}
.contact-sections {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.contact-section {
  display: flex;
  gap: 1rem;
  align-items: center;
  text-decoration: none;
}
.contact-icon {
  height: 64px;
  width: 64px;
  cursor: pointer;
}
.contact-details {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  font-family: "Poppins", sans-serif;
  color: #fff;
  cursor: pointer;
}
.contact-icon:hover {
  transform: rotate(-20deg);
  transition: 0.3s all;
}
.contact-details:hover {
  text-decoration: underline;
  transition: 0.3s linear;
}
.trc-icon {
  height: 50px;
  width: 65px;
}
.trc-icon:hover {
  transform: none !important;
}
.trc-details {
  font-size: 14px;
  line-height: 20px;
}
.trc-details:hover {
  text-decoration: none;
}
.trc-btn {
  background: #277770;
  border-radius: 17px;
  color: #fff;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  text-align: center;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 10px;
  cursor: pointer;
  border: 1px solid transparent;
  height: 39px;
  width: 21rem;
}
.trc-btn a {
  color: #fff;
}
.trc-btn:hover {
  background: transparent;
  transition: 0.3s linear;
  border: 1px solid #277770;
  color: #277770;
}
.trc-btn:hover a {
  color: #ffffff;
}
.footer-r {
  flex: 1;
  position: relative;
}
.footer-logo {
  background: #142b21;
  height: 423px;
  width: 423px;
  position: absolute;
  right: -3rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  top: -12rem;
}
.footer-logo > img {
  height: 216px;
  width: 265px;
}
.social-icons {
  position: absolute;
  height: 69px;
  width: 69px;
}
.social-icons:hover {
  transform: rotate(180deg) !important;
  transition: 0.3s all !important;
}
.linkedin {
  right: 18rem;
  top: -15rem;
}
.instagram {
  right: 23rem;
  top: -9rem;
}
.behance {
  right: 26rem;
  top: -2rem;
}
.facebook {
  right: 24rem;
  top: 6rem;
}
.pinterest {
  right: 18.5rem;
  top: 12.8rem;
}

@media screen and (max-width: 1025px) {
  .footer-section {
    padding-left: 5vw;
    min-height: 34rem;
  }
  .footer-title {
    font-size: 38px;
  }
  .footer-line {
    width: 18rem;
  }
  .footer-circle {
    left: 11rem;
  }
  .contact-icon {
    height: 42px;
    width: 42px;
  }
  .contact-details {
    font-size: 18px;
    line-height: 25px;
  }
  .footer-logo {
    height: 350px;
    width: 350px;
  }
  .footer-logo > img {
    height: 135px;
    width: 220px;
  }
  .social-icons {
    height: 60px;
    width: 60px;
  }
  .linkedin {
    right: 14rem;
    top: -15rem;
  }
  .instagram {
    right: 19rem;
    top: -9rem;
  }
  .behance {
    right: 21rem;
    top: -2rem;
  }
  .facebook {
    right: 18rem;
    top: 5rem;
  }
  .pinterest {
    right: 11.4rem;
    top: 10.5rem;
  }
}
@media screen and (max-width:768px){
  .footer-section{
    flex-direction: column;
    padding-bottom: 5rem;
  }
  .footer-r{
    display: flex;
    justify-content: center;
    min-height: 15rem;
  }
  .footer-title {
    font-size: 30px;
}
.footer-line {
  width: 16rem;
}
.footer-circle {
  left: 9rem;
}
.contact-sections{
  gap: 10px;
}
.contact-section{
  gap: 10px;
}
.contact-details {
  font-size: 14px;
  line-height: 20px;
}
  .footer-logo{
    top: 0;
    right: unset;
    height: 200px;
    width: 200px;
  }
  .footer-logo > img {
    height: 57px;
    width: 107px;
}
.social-icons{
  top: unset;
  right: unset;
  height: 45px;
  width: 45px;
}
.linkedin {
  left: -9.5rem;
  bottom: 40px;
}
.facebook {
  left: 6.5rem;
  bottom: 38px;
}
.instagram {
  left: -6.5rem;
  bottom: -17px;
}
.pinterest {
  left: 3rem;
  bottom: -17px;
}
.behance {
  right: -17px;
  bottom: -30px;
}
}
