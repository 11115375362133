@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@font-face {
  src: url('../public/fontfamily/SummerLadiesDEMO.ttf');
  font-family: "summer-ladies";
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.wh-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}
.wrapper{
  max-width: 1700px;
}
::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #8e3ccb;
}
::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

body{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* font-family: 'Poppins', sans-serif; */